import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2069124008/src/biblia-frontend/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/codebuild/output/src2069124008/src/biblia-frontend/src/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_C2QvvtgUo3 from "/codebuild/output/src2069124008/src/biblia-frontend/src/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import analytics_client_mZYnNRQBH8 from "/codebuild/output/src2069124008/src/biblia-frontend/src/plugins/analytics.client.js";
import outside_click_gSkWRKPnA1 from "/codebuild/output/src2069124008/src/biblia-frontend/src/plugins/outside-click.js";
import showFootnote_zc7YQV1cPA from "/codebuild/output/src2069124008/src/biblia-frontend/src/plugins/showFootnote.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_C2QvvtgUo3,
  analytics_client_mZYnNRQBH8,
  outside_click_gSkWRKPnA1,
  showFootnote_zc7YQV1cPA
]