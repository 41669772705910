import { default as _91_91endVerse_93_93yRKOMn6YgtMeta } from "/codebuild/output/src2069124008/src/biblia-frontend/src/pages/citanie/[translation]/[book]/[chapter]/[[verse]]/[[endVerse]].vue?macro=true";
import { default as _91title_93MukePGqiuNMeta } from "/codebuild/output/src2069124008/src/biblia-frontend/src/pages/clanky/[title].vue?macro=true";
import { default as index6MzIbBScYlMeta } from "/codebuild/output/src2069124008/src/biblia-frontend/src/pages/index.vue?macro=true";
import { default as _91title_93l1zi7wnL3ZMeta } from "/codebuild/output/src2069124008/src/biblia-frontend/src/pages/verse/[title].vue?macro=true";
import { default as vyhladavanieFIlJZQy77SMeta } from "/codebuild/output/src2069124008/src/biblia-frontend/src/pages/vyhladavanie.vue?macro=true";
export default [
  {
    name: "citanie",
    path: "/citanie/:translation()/:book()/:chapter()/:verse?/:endVerse?",
    meta: _91_91endVerse_93_93yRKOMn6YgtMeta || {},
    component: () => import("/codebuild/output/src2069124008/src/biblia-frontend/src/pages/citanie/[translation]/[book]/[chapter]/[[verse]]/[[endVerse]].vue")
  },
  {
    name: "clanky",
    path: "/clanky/:title()",
    meta: _91title_93MukePGqiuNMeta || {},
    component: () => import("/codebuild/output/src2069124008/src/biblia-frontend/src/pages/clanky/[title].vue")
  },
  {
    name: "index",
    path: "/",
    meta: index6MzIbBScYlMeta || {},
    component: () => import("/codebuild/output/src2069124008/src/biblia-frontend/src/pages/index.vue")
  },
  {
    name: "verse",
    path: "/verse/:title()",
    meta: _91title_93l1zi7wnL3ZMeta || {},
    component: () => import("/codebuild/output/src2069124008/src/biblia-frontend/src/pages/verse/[title].vue")
  },
  {
    name: "vyhladavanie",
    path: "/vyhladavanie",
    component: () => import("/codebuild/output/src2069124008/src/biblia-frontend/src/pages/vyhladavanie.vue")
  }
]